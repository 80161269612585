import auth0 from "auth0-js";

import clientConfig from "@/client/config";

import { localStorageKeys } from "./utils";

// // Specified in Auth0 dashboard -> Auth Pipeline -> Rules -> Add user metadata
export const USER_PROFILE_RULE_KEY = "https://suppliers.fonoa.com/user_metadata";

export const webAuth = new auth0.WebAuth({
  domain: clientConfig.AUTH0_DOMAIN() as string,
  clientID: clientConfig.AUTH0_PUBLIC_CLIENT_ID() as string,
  redirectUri: clientConfig.AUTH0_CALLBACK_URL(),
  audience: `https://${clientConfig.AUTH0_DOMAIN()}/userinfo`,
  responseType: "token id_token",
  scope:
    "openid email profile user_metadata read:users update:users update:email update:password read:current_user_metadata",
});

function setSession(authResult: auth0.Auth0DecodedHash, profile: any) {
  const expiresAt = JSON.stringify((authResult.expiresIn || 0) * 1000 + new Date().getTime());
  localStorage.setItem(localStorageKeys.access_token, authResult.accessToken || "");
  localStorage.setItem(localStorageKeys.id_token, authResult.idToken || "");
  localStorage.setItem(localStorageKeys.expires_at, expiresAt);
  localStorage.setItem(localStorageKeys.profile, JSON.stringify(profile));
}

export function updateUserData(profile: any) {
  localStorage.setItem(localStorageKeys.profile, JSON.stringify(profile));
}

export function getUserData() {
  try {
    const profileData = JSON.parse(localStorage.getItem(localStorageKeys.profile) || "");
    return {
      ...profileData,
      ...JSON.parse(profileData?.profile),
      name: JSON.parse(profileData?.profile).name,
      email: profileData?.email,
      lang: profileData?.lang,
      tin: profileData?.tin,
      company_id: profileData?.company_id,
      tenant_id: profileData?.tenant_id,
      country: profileData?.country,
    };
  } catch (e) {
    console.error(e);
    signout();
    return null;
  }
}

export const sendSetPasswordEmail = (email: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    webAuth.changePassword(
      { connection: clientConfig.AUTH0_CONNECTION() as string, email },
      (err) => {
        if (err) {
          console.log(err);
          return reject(err);
        }

        return resolve();
      }
    );
  });
};

export function signout() {
  localStorage.removeItem(localStorageKeys.access_token);
  localStorage.removeItem(localStorageKeys.id_token);
  localStorage.removeItem(localStorageKeys.expires_at);
  localStorage.removeItem(localStorageKeys.profile);
}

export function isAuthenticated() {
  const data = localStorage.getItem(localStorageKeys.expires_at);
  if (data === null) {
    return false;
  }
  const expiresAt = JSON.parse(data);
  return new Date().getTime() < expiresAt;
}

export function passwordReset(email: string): Promise<void> {
  return new Promise((resolve, reject) => {
    webAuth.changePassword(
      { connection: clientConfig.AUTH0_CONNECTION() as string, email },
      (err) => {
        if (err) {
          console.log(err);
          return reject(err);
        }

        return resolve();
      }
    );
  });
}

export function signIn(email: string, password: string, country: string): Promise<void> {
  return new Promise((resolve, reject) => {
    webAuth.client.login(
      {
        realm: clientConfig.AUTH0_CONNECTION() as string,
        username: email,
        password,
      },
      (err, authResult) => {
        if (err) {
          console.log(err);
          return reject(err);
        }

        webAuth.client.userInfo(authResult.accessToken, (error, profile) => {
          if (!error) {
            const fullProfile = {
              ...profile[USER_PROFILE_RULE_KEY],
              email,
            };

            if (fullProfile?.country !== country) {
              return reject("User’s country does not match app’s country");
            }

            setSession(authResult, fullProfile);
            return resolve();
          }
          return reject(error);
        });
      }
    );
  });
}

export function handleAuthentication(): Promise<void> {
  return new Promise((resolve, reject) => {
    webAuth.parseHash((err, authResult) => {
      if (authResult?.accessToken && authResult?.idToken) {
        setSession(authResult, {});
        return resolve();
      } else if (err) {
        console.log(err);
        return reject(err);
      }
    });
  });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {};
